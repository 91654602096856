import { initializeApp } from "firebase/app";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  CACHE_SIZE_UNLIMITED,
} from "firebase/firestore";
import { initializeAuth, indexedDBLocalPersistence } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCYrpZMobu7gCYsbTsM1cW0sbogs8y7InQ",
  authDomain: "metropol-403216.firebaseapp.com",
  projectId: "metropol-403216",
  storageBucket: "metropol-403216.appspot.com",
  messagingSenderId: "119353793713",
  appId: "1:119353793713:web:514ad4e0304186d6ce9690",
  measurementId: "G-DX7J1DDQRC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  }),
});
const auth = initializeAuth(app, {
  persistence: indexedDBLocalPersistence,
});
export { db, auth };
