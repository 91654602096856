import { createRouter, createWebHashHistory } from "vue-router";
import { useUserStore } from "@/store/userStore";

const routes = [
  {
    path: "/",
    name: "appshell",
    component: () => import("../views/appShell.vue"),
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/homeView.vue"),
        meta: {
          requiresAuth: true,
          desc: "Inicio",
        },
      },
      {
        path: "esce",
        name: "esce",
        redirect: "/esce/list",
        meta: {
          requiresAuth: true,
          desc: "Escenarios",
        },
        children: [
          {
            path: "add",
            name: "esce_add",
            component: () => import("../views/esce/esceAddView.vue"),
          },
          {
            path: "list",
            name: "esce_list",
            component: () => import("../views/esce/esceListView.vue"),
          },
        ],
      },
      {
        path: "escep",
        name: "escep",
        redirect: "/escep/list",
        meta: {
          requiresAuth: true,
          desc: "Parámetros",
        },
        children: [
          {
            path: "add",
            name: "escep_add",
            component: () => import("../views/escep/escepAddView.vue"),
          },
          {
            path: "list",
            name: "escep_list",
            component: () => import("../views/escep/escepListView.vue"),
          },
        ],
      },
      {
        path: "escp",
        name: "escp",
        redirect: "/escp/list",
        meta: {
          requiresAuth: true,
          desc: "Programación",
        },
        children: [
          {
            path: "add",
            name: "escp_add",
            component: () => import("../views/escp/escpAddView.vue"),
          },
          {
            path: "list",
            name: "escp_list",
            component: () => import("../views/escp/escpListView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/loginView.vue"),
    meta: {
      desc: "Login",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/logoutView.vue"),
    meta: {
      desc: "Logout",
    },
  },
  {
    path: "/noaccess",
    name: "noaccess",
    component: () => import("../views/noaccessView.vue"),
    meta: {
      desc: "Noaccess",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "nofound",
    component: () => import("../views/nofoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  const { uid } = useUserStore();
  if (to.meta.requiresAuth && !uid) return "/login";
});

export default router;
